.header {
	display: grid;
	grid-template: auto / auto auto;
	background-color: black;
	color: white;
	padding-bottom: 0.25vw;
}

.sub-header-1 {
	font-size: 4vw;
	padding-left: 1vw;
	justify-content: start;
	text-align: start;
}

.sub-header-2 {
	display: grid;
	grid-template: auto / auto auto auto auto auto;
	font-size: 1.5vw;
	text-align: end;
	justify-content: end;
	padding-right: 2vw;
	align-items: end;
}

.sub-header-3 {
	padding-left: 2vw;
	text-align: right;
}

.hyperlink {
	color: white;
	text-decoration: none;
}

.hyperlink:hover {
	color: grey;
	transition: all .8s ease;
	text-decoration: none;
}